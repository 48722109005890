import { createPublicEnv } from '@vcc-www/public-env';
import { getElasticVariablesFromEnv } from '@vcc-package/opentelemetry';

export const { PublicEnv, getPublicEnv } = createPublicEnv({
  buildSourceVersion: process.env.BUILD_SOURCEVERSION,
  hostname: process.env.HOSTNAME,
  publicHost: process.env.NEXT_PUBLIC_HOST,
  deployEnv: process.env.DEPLOY_ENV,
  ...getElasticVariablesFromEnv(),
});
