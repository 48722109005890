import { useRef } from 'react';

export function useCallOnce<T, P extends any[]>(fn: (...args: P) => T) {
  const hasCalled = useRef(false);

  return (...args: P) => {
    if (hasCalled.current) {
      return null;
    }

    hasCalled.current = true;

    return fn(...args);
  };
}
