import React, { useState } from 'react';
import { StyleRenderer, StyleTags } from 'vcc-ui';

type StyleRendererWithCache = StyleRenderer & { cache: Record<string, any> };

/**
 *
 * @param getStyleRenderer Function that returns the StyleRenderer
 * @returns A function that only flushes styles that haven't been flushed before
 */
export const useFlushStyles = (getStyleRenderer: () => StyleRenderer) => {
  const [flushedRules] = useState(() => {
    return new Set();
  });

  return function flushStyles(renderer: StyleRenderer) {
    const globalStyleRender = renderer as StyleRendererWithCache;

    const currentFlushRenderer = getStyleRenderer() as StyleRendererWithCache;

    // Only flush styles that haven't already been flushed
    // otherwise many unneeded stylesheets will be accumulated

    // Problem with flushing everything everytime:
    // Let's say useServerInsertedHTML is called 3 times (number depends on Suspense boundaries):
    // 1. First time, we render components that generate 10 stylesheets, we flush 10 stylesheets
    // 2. Second time, we render components that generate 5 stylesheets, we flush 10 + 5 stylesheets
    // 2. Third time, we render components that generate 7 styles, we flush 10 + 5 + 7 styles

    // Total stylesheets is now: 10 + (10 + 5) + (10 + 5 + 7) = 47 stylesheets
    // When we only needeed 10 + 5 + 7 = 22 stylesheets

    // This only flushes the stylesheets that haven't been flushed before
    const entries = Object.entries(globalStyleRender.cache).filter(([key]) => {
      return !flushedRules.has(key);
    });

    if (!entries.length) {
      return null;
    }

    currentFlushRenderer.cache = Object.fromEntries(entries);

    Object.keys(globalStyleRender.cache).forEach(
      flushedRules.add,
      flushedRules,
    );

    return <StyleTags renderer={currentFlushRenderer} />;
  };
};
